// MODULE IMPORT
import React from 'react'
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"


// ICONS IMPORT
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

// CSS
import './header.css'

// IMAGES IMPORT
import Portrait from '../../img/portrait3.png'

// COMP IMPORT
import Contact from './headerContact'

const Header = () => {
  return (
    <section id="header">
      <header>
        <div className="container header__container">
          <div className="header__description">
            <h2>Bienvenue chez</h2>
            <motion.h1
            animate={{ x: 0, opacity: 1}}
            initial={{x: -100, opacity: 0}}
            transition={{ type: 'spring', duration: 3, bounce: 0.2, delay: 0}}
            >HOLA MANAGEMENT</motion.h1>
            <motion.h3
            className="text-light"
            animate={{ x: 0, opacity: 1}}
            initial={{x: -100, opacity: 0}}
            transition={{ type: 'spring', duration: 3, bounce: 0.2, delay: 0.3}}
            >Accompagnatrice de projets <span className="text-highlight">créatifs</span>, <span className="text-highlight">artistiques</span> et <span className="text-highlight">professionnels</span>, je vous invite à découvrir mes différentes offres de services.</motion.h3>
{/*             <motion.h4
            className="info_add"
            animate={{ x: 0, opacity: 1}}
            initial={{x: -100, opacity: 0}}
            transition={{ type: 'spring', duration: 3, bounce: 0.2, delay: 0.5}}
            >Et aussi dans des formations en accompagnement de projets.</motion.h4> */}
            <Contact />
            <a href="#about" id="smooth-a" className="scroll__down">C'est par là &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRightLong} /></a>
          </div>
        </div>
        <div className="portrait">
            <img className="img-test" src={Portrait} alt="" />
        </div>
      </header>
    </section>
  )
}

export default Header