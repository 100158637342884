// MODULES IMPORT
import React, {useEffect} from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

// CSS
import './services.css'

// ICONS IMPORT
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'



const Services = () => {

  const {ref, inView} = useInView({
    threshold: 0.2
  });

  const animation = useAnimation();

  useEffect(() => {
    console.log("use effect hook, inView=", inView);
    if(inView){
      animation.start({
        y: 0,
        opacity: 1,
        transition: { 
          type: 'spring', bounce: 0.3, duration: 2
        }
    });
  }
    if(!inView){
      animation.start({
        opacity: 0
      });
    }

  }, [inView]);


  return (
    <section id="services" ref={ref}>
      <motion.h2
      animate={animation}>
        Mes services</motion.h2>
      <motion.h4
      animate={animation}>
        <em>L’accompagnement étant personnalisé, la liste n’est pas exhaustive mais a vocation à vous donner un aperçu de ce qui pourra être réalisé ensemble.</em></motion.h4>
      <motion.div 
      className="container services__container"
      animate={animation}
      >
        {/* FORMATIONS */}
        <article className="service">
            <div className="service__head">
              <h3>Formation</h3>
            </div>
            <ul className="service__list">
              <li>
                <p><FontAwesomeIcon icon={faCircleNotch} class="service__list__icon"/> En collaboration avec <a href="https://www.coachinband.fr/" target="_blank"  rel="noreferrer" >Coach'in Band</a> :</p>
              </li>
              <li>
                <p><em><strong>“L’artiste et son environnement professionnel”</strong></em></p>
              </li>
              <li>
                <p><FontAwesomeIcon icon={faCircleNotch} class="service__list__icon"/> 3 thématiques : </p>
              </li>
              <li>
                <p> <FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Environnement professionnel </p>
              </li>
              <li>
                <p> <FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Développer son projet</p>
              </li>
              <li>
                <p> <FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Savoir communiquer</p>
              </li>
              <li>
                  <a href='http://bit.ly/3YrOaQS' target='_blank' download>Découvrir le détail des formations</a>
              </li>
            </ul>
        </article>
        
        {/* PROJECTS */}
        <article className="service" >
          <div className="service__head">
            <h3>Accompagnement de projets, conseil</h3>
          </div>
          <ul className="service__list">
            <li>
              <p> <FontAwesomeIcon icon={faCheck} class="service__list__icon"/> <strong>Formalisation</strong> de votre projet</p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> <strong>Plan d’action</strong> pour la concrétisation d’un projet</p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Identification et <strong>mise en valeur des compétences</strong> de votre entreprise et de votre projet</p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Conseil en <strong>communication</strong> de projet</p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Accompagnement pour la <strong>mise en place des solutions</strong> identifiées</p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Accompagnement dans <strong>l’organisation des tâches</strong> / choix des bons outils</p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Identification des <strong>clés de réussite</strong> de votre projet</p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Suivi de production - spectacle vivant</p>
            </li>
          </ul>
        </article>

        {/* INDIVIDUALS */}
        <article className="service">
          <div className="service__head">
            <h3>Pour les particuliers</h3>
          </div>
          <ul className="service__list">
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Identification et <strong>mise en valeur de vos compétences</strong> </p>
            </li>
            <li>
              <p><FontAwesomeIcon icon={faCheck} class="service__list__icon"/> Accompagnement à la <strong>préparation de vos candidatures</strong> (LM, CV et entretiens)</p>
            </li>
          </ul>
        </article>
      </motion.div>
    </section>
  )
}

export default Services