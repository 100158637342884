// MODULES IMPORT
import  React, { useState, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

// CSS
import './about.css'

// ICONS IMPORT
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons'




const About = () => {

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const {ref, inView} = useInView();
  const animation = useAnimation();

  useEffect(() => {
    console.log("use effect hook, inView=", inView);
    if(inView){
      animation.start({
        opacity: 1,
        transition: { 
          type: 'linear', duration: 1, 
        }
    });
  }
    if(!inView){
      animation.start({opacity: 0})
    }

  }, [inView]);
  
/*   const Collapse = ({ collapsed, children }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(collapsed);
  
    return (
      <>
        <button
          className="collapse-button"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? 'Show' : 'Hide'} content
        </button>
        <div
          className={`collapse-content ${isCollapsed ? 'collapsed' : 'expanded'}`}
          aria-expanded={isCollapsed}
        >
          {children}
        </div>
      </>
    );
  }; */


  return (
    <section id="about">
      <h2 id="about-scroll">À propos</h2>
      <div className="container about__container">
        <div ref={ref} className="about__menu">
              <motion.article
                className={toggleState === 1 ? "about__menuCard active__menuCard" : "about__menuCard"}
                onClick={() => toggleTab(1)}
                animate={animation}
              >
                <FontAwesomeIcon icon={faGraduationCap} className="about__icon" />
                <h5>Mon parcours</h5>
              </motion.article>
              <motion.article
                className={toggleState === 2 ? "about__menuCard active__menuCard" : "about__menuCard"}
                onClick={() => toggleTab(2)}
                animate={animation}
              >
                <FontAwesomeIcon icon={faList} className="about__icon" />
                <h5>Mon offre</h5>
              </motion.article>
              <motion.article
                className={toggleState === 3 ? "about__menuCard active__menuCard" : "about__menuCard"}
                onClick={() => toggleTab(3)}
                animate={animation}
              >
                <FontAwesomeIcon icon={faHandHoldingHeart} className="about__icon" />
                <h5>Mon approche</h5>
              </motion.article>
              <motion.article
                className={toggleState === 4 ? "about__menuCard active__menuCard" : "about__menuCard"}
                onClick={() => toggleTab(4)}
                animate={animation}
              >
                <svg id="svg-hola" width="30px" height="30px" className="about__icon" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 504.87 504.87"><defs></defs><path class="ea35131a-ee9a-45c4-9099-6ba92ef7f8f6" d="M347.28,4.07C208.14,4.07,95.34,116.86,95.34,256s112.8,251.93,251.94,251.93S599.21,395.14,599.21,256,486.41,4.07,347.28,4.07Zm79,179.26q16,0,16,17.63,0,8.49-4.28,13t-11.76,4.58a15.41,15.41,0,0,1-11-4.12c-2.93-2.74-4.38-7.24-4.38-13.51Q410.84,183.32,426.25,183.33ZM226.88,126.91h21.69v61.51a134.78,134.78,0,0,1-1.07,18.41h1.36A44.33,44.33,0,0,1,267,190.57a59.24,59.24,0,0,1,27-5.93q26.25,0,39.37,12.47t13.13,39.58v93.37l-21.7,0V237.52q0-17.49-8-26.12t-24.95-8.61q-22.59,0-33,12.27t-10.37,40.22V330.1H226.88ZM438.07,329.32q-4.27,4.56-11.77,4.57a15.42,15.42,0,0,1-11.08-4.08q-4.38-4.15-4.38-13.54,0-8.76,4-13.26t11.43-4.51q7.58,0,11.82,4.51t4.28,13.26Q442.35,324.76,438.07,329.32Z" transform="translate(-94.84 -3.57)" /></svg>
                <h5>HOLA ?</h5>
              </motion.article>
        </div>
        <div className="about__content">
        <motion.div
                className={toggleState === 1 ? "about__contentCard  active__contentCard about__journey" : "about__contentCard about__journey"}
                animate={animation}
              >
                <p>Après un <strong>Master en Economie et Gestion d’Entreprises, parcours Management et carrières d'artistes</strong>, j’ai exercé en tant qu’<strong>agent artistique</strong> et <strong>responsable de communication</strong> pendant 5 ans.</p>
                <br /><p>Aujourd’hui, toujours fidèle au milieu de la culture, je travaille en parallèle  dans le service d’<strong>Action Culturelle</strong> de l’<strong>Auditorium-Orchestre national de Lyon</strong> pour le projet <strong>DEMOS</strong>. </p>
                <br /><p>Mes expériences m’ayant sensibilisé à l’importance de la santé mentale pour tous, je me forme en <strong>psychologie</strong>. Ces connaissances viennent  compléter mes compétences professionnelles actuelles.</p>

              </motion.div>

              <motion.div
                className={toggleState === 2 ? "about__contentCard  active__contentCard about__offer" : "about__contentCard about__offer"}
                animate={animation}
              >
                <p>• Un <strong>accompagnement personnalisé</strong> dans la réalisation de vos projets.</p>
                <ul className="p-align-left">
                <li><strong>Professionnels et entreprise</strong> : par la structuration, <strong>l’identification des forces et faiblesses du projet</strong> et la mise en place d’un plan d’action en conséquence</li>

                <li><strong>Particuliers</strong> : en apprenant à <strong>identifier et mettre en valeur vos compétences</strong></li>
                </ul>
                <br /><p>• Un <strong>regard extérieur bienveillant</strong> au service de vos projets et de vos objectifs</p>
                <p>•<strong> Des formations</strong> en gestion de projets artistiques, en collaboration avec <a href="https://www.coachinband.fr/" target="_blank"  rel="noreferrer" >Coach'in Band</a></p>
              </motion.div>

              <motion.div
                className={toggleState === 3 ? "about__contentCard  active__contentCard about__approach" : "about__contentCard about__approach"}
                animate={animation}
              >
                <p>Un accompagnement personnalisé vous est proposé</p>
                <p><strong>Nous travaillons et avançons ensemble</strong>, main dans la main.</p>
                <p>Je vous guide et vous donne des clés pour que vous puissiez, à terme, <strong>avancer en autonomie</strong>.</p>
                <br /><p>Une approche bienveillante, un <strong>soutien important</strong> dans vos projets, une <strong>sensibilité</strong> qui m’aide à <strong>comprendre vos attentes</strong> et vous guider.</p>
              </motion.div>
              <motion.div
                className={toggleState === 4 ? "about__contentCard  active__contentCard about__hola" : "about__contentCard about__hola"}
                animate={animation}
              >
                <p>Avec Hola j’ai souhaité continuer à <strong>soutenir et accompagner les projets</strong> de ceux qui en ont besoin, en particulier <strong>les jeunes et les artistes/créateurs</strong>.</p>
                <br /><p><strong>HOLA management</strong> c’est apprendre à vous connaître vous-même, votre entreprise, à <strong>identifier vos forces</strong> et vos faiblesses et les mettre en regard de vos projets personnels et professionnels.</p>
                <br /><p><strong>HOLA c’est apprendre à mettre en lumière vos compétences et vos projets.</strong></p>
                <br />
                <p><strong>HOLA</strong> : <em><strong>H</strong>elping <strong>O</strong>rganizations and people to <strong>L</strong>ight up their <strong>A</strong>bilities</em></p>

              </motion.div>
        </div>
      </div>

    </section>
  )
}

export default About