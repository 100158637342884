import './App.css'

/* IMPORT COMPONENT */
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import Services from './components/services/Services'
import Testimonial from './components/testimonial/Testimonial'
import Footer from './components/footer/footer'

function App() {
  return (
    <div>
      <Header />
      <Nav />
      <About />
      <Services />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
