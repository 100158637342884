// MODULE IMPORT
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'

// PAGE IMPORT
import Socials from './socialContact'

// CSS
import './contact.css'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a4kxlal', 'template_toeid79', form.current, 'ioaQh_kvuLDSjjZPy')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      
      e.target.reset();
  };

  return (
    <section id="contact">
      <h2>Discutons en!</h2>
      <Socials />
      <div className="container contact__container">
      <div className="contact__desc">
          <p>Je vous accompagne (PME, associations, indépendants et particuliers) dans la <strong>réalisation de vos objectifs professionnels</strong> en prenant en compte vos besoins et vos moyens, convaincue qu’il y a <strong>une solution à chaque profil et chaque budget</strong>.</p>
          <br /><p><strong>1er rendez-vous gratuit</strong></p>
          <p><em>Prix final sur devis.</em></p>
        </div>
        
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="Nom" placeholder="votre nom" required id="FirstName"/>
          <input type="email" name="email" placeholder="votre email" required id="Address"/>
          <textarea name="message"  rows="7" placeholder="votre message" required id="Message"></textarea>
          <button type="submit" className="contact-button">Envoyer</button>
        </form>
      </div>
    </section>
  )
}

export default Contact