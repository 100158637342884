// MODULE IMPORT
import React from 'react'
import {  Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// CSS
import './testimonial.css'
    // Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// IMAGES IMPORT
import CLT1 from '../../logos/clients/laCascade.svg'
import CLT2 from '../../logos/clients/RSBA.png'
import PlaceholderFemale from '../../img/femalePlaceholder.jpg'

const data = [
  {
    avatar: CLT1,
    name: 'La Cascade',
    clienturl: 'https://la-cascade.net',
    titleclient: 'Hotel Restaurant Bar',
    review: 'Perrine nous a secondés à l\'ouverture de notre établissement en nous aidant à mettre en place une communication qui nous ressemble, qui véhicule nos valeurs et reflète notre personnalité.  Elle a su mettre le doigt sur nos points forts et les a exposés subtilement via nos différents supports de communication. Merci à HOLA pour son soutien et ses conseils !'
  },
  {
    avatar: CLT2,
    name: 'RSB Artists',
    clienturl: 'https://la-cascade.net',
    titleclient: 'Agence artistique de chanteurs lyriques et chefs d\'orchestre.',
    review: 'Depuis que Perrine Laurent (Hola Management) nous conseille et gère la communication de l\'agence RSBA, nous avons franchi un cap et disposons, nous et nos artistes, d\'une bonne visibilité sur nos réseaux sociaux.'
  },
  {
    avatar: PlaceholderFemale,
    name: 'Lison',
    titleclient: 'Étudiante',
    review: 'J\'ai pu faire appel aux services de Perrine pour ma candidature et cela m\'a permis d\'avoir le poste que je souhaitais. Grâce à ses conseils j\'ai pu apprendre à identifier mes compétences et les mettre en avant correctement. Son aide m\'a également permis de devenir plus à l\'aise avec la construction d\'un courrier. Je recommande fortement à toute personne ayant besoin d\'aide de faire appels aux services de Perrine sans hésiter, elle vous appuiera dans votre démarche avec sa grande gentillesse et sa bienveillance.'
  },
]

const Testimonial = () => {
  return (
    <section id='testimonal' className="shapes">
      <h2>Ce qu'ils en disent</h2>
      <Swiper className='container testimonials__container'
            modules={[Pagination]}
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, titleclient, review, clienturl}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client__image">
                  <a href={clienturl} target='__blank' rel='noreferrer noopener'><img src={avatar} alt="" /></a>
                </div>
                <h4 className="client__name">{name}</h4>
                <h5 className="client__title">{titleclient}</h5>
                <small className="client__review">{review}</small>
              </SwiperSlide>
            )
          }
          )
        }

      </Swiper>
    </section>
  )
}

export default Testimonial