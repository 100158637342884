import React from 'react'
import './contactSocial.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const Contact = () => {
  return (
      <div className="contact__socialMenu">
        <a href="https://www.instagram.com/hola_mgmt/" target="_blank"  rel="noreferrer" className="contact__social contact__social-buttonInsta"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="https://wa.me/33683858007" target="_blank"  rel="noreferrer" className="contact__social contact__social-buttonWa"><FontAwesomeIcon icon={faWhatsapp} /></a>
        <a href="https://www.linkedin.com/in/perrine-laurent/" target="_blank"  rel="noreferrer"className="contact__social contact__social-buttonLinkedin"><FontAwesomeIcon icon={faLinkedinIn} /></a>
      </div>
  )
}

export default Contact