import React from 'react'
import './headerContact.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faComments2 } from '@fortawesome/free-solid-svg-icons'
import { faComments } from '@fortawesome/free-regular-svg-icons'

const Contact = () => {
  return (
      <div className="header__socialMenu">
        <a href="https://www.instagram.com/hola_mgmt/" target="_blank"  rel="noreferrer" className="button__social button__insta"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="https://www.linkedin.com/in/perrine-laurent/" target="_blank"  rel="noreferrer"className="button__social button__linkedin"><FontAwesomeIcon icon={faLinkedinIn} /></a>
{/*        
        <a href="#contact" className="button__social button__contact"><FontAwesomeIcon icon={faComments} className="test" /></a>
*/}
      </div>
  )
}

export default Contact