// MODULE IMPORT
import React, { useState } from 'react'
import ScrollSpy from 'react-scrollspy-navigation';

// CSS
import './nav.css'

// ICONS IMPORT
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-solid-svg-icons'

const Nav = () => {

  return (
    <nav>
      <ScrollSpy>
        <a href="#header" ref={React.createRef()}>
          <FontAwesomeIcon icon={faHome} />
        </a>
        <a href="#about" ref={React.createRef()}>
         <FontAwesomeIcon icon={faAddressCard} />
        </a>
        <a href="#services" ref={React.createRef()}>
         <FontAwesomeIcon icon={faHandshake} />
        </a>
        <a href="#contact" ref={React.createRef()}>
          <FontAwesomeIcon icon={faComment} />
        </a>
    </ScrollSpy>
    </nav>
  )
}

export default Nav