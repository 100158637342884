// MODULE IMPORT
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// CSS
import './footer.css'

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <footer class="footer">
      <div class="waves">
          <div class="wave" id="wave1"></div>
          <div class="wave" id="wave2"></div>
          <div class="wave" id="wave3"></div>
          <div class="wave" id="wave4"></div>
      </div>
      <ul class="menu">
        <li class="menu__item"><a class="menu__link" href="#header">Home</a></li>
        <li class="menu__item"><a class="menu__link" href="#about">À propos</a></li>
        <li class="menu__item"><a class="menu__link" href="#services">Services</a></li>
        <li class="menu__item"><a class="menu__link" href="#contact">Contact</a></li>
      </ul>
      <p>Kaelmnop &copy;2023 | All Rights Reserved.  
      <Button className="legal" onClick={handleClickOpen('paper')}>Mentions légales</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Mentions légales</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
              <p>
              <br />HOLA Management 
              <br />Perrine LAURENT EI
              <br />112 rue André Bollier 
              <br />69007, Lyon
              <br />perrine@hola-management.com
              <br />06.83.85.80.07
              </p>
              <p>
                <br /><h2>Hébergement : </h2>
                OVH 
                <br />OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.
                <br />Tel : 1017
              </p>
              <p>
                <br /><h2>Photo</h2>© John Zougas
              </p>
{/*               <p>
                <br /><h2>Conditions Générales de Ventes (CGV)</h2>
                Services d’accompagnement et conseil en gestion de projets. 
                <br />Les prix sont communiqués sur devis sur la base d’un TJM de 350€ TTC
              </p>
              <p>
                <br />Modalités de paiement :
                <ol>
                  sont acceptés:
                  <li>Les virements bancaires</li>
                  <li>Les paiements en espèces</li>
                </ol>
                <br />TVA non applicable, article 293B du CGI
              </p>
              <p>
                <br /><h3>Retard de paiement : </h3>
                Les factures doivent être réglées sous 30 jours à compter de la date d’émission. Passé ce délai, une pénalité de retard de 10 % sera appliquée, ainsi qu’une indemnité forfaitaire de 40 € due au titre des frais de recouvrement. Pas d’escompte pour règlement anticipé.
              </p>
              <p>
                <br /><h3>Droit de rétractation : </h3>
                14 jours 
                <br />Exception dans le cas où le service concerné serait totalement exécuté avant la fin du délai de rétractation et dont l'exécution a commencé avec l’accord du client ou à la demande expresse de celui-ci. 
                <br />Un service peut être considéré comme exécuté si le client a effectué une réservation sans avoir honoré la prestation de sa présence et ce, sans annulation préalable.
              </p>
              <p>
                <br /><h3>Modalités de règlement des litiges : </h3>
                tribunal compétent et possibilité de recourir à un médiateur
              </p> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
      </p>
  </footer>
   
  );
}
